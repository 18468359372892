@font-face {
    font-family: 'Geogrotesque SmBd';
    src: url('//cdn.shopify.com/s/files/1/0269/1239/1254/t/6/assets/Geogrotesque-SemiBold.otf');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Geogrotesque Rg';
    src: url('//cdn.shopify.com/s/files/1/0269/1239/1254/t/6/assets/Geogrotesque-Regular.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Geogrotesque UlLg';
    src: url('//cdn.shopify.com/s/files/1/0269/1239/1254/t/6/assets/Geogrotesque-UltraLight.otf');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Geogrotesque Md';
    src: url('//cdn.shopify.com/s/files/1/0269/1239/1254/t/6/assets/Geogrotesque-Medium.otf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Geogrotesque Lg';
    src: url('//cdn.shopify.com/s/files/1/0269/1239/1254/t/6/assets/Geogrotesque-Light.otf');
    font-weight: 300;
    font-style: normal;
}
